.navbar-custom {
    position: fixed;
    background-color: #145DA0;
    box-shadow: none;
    font: 700 0.875rem/0.875rem "Open Sans", sans-serif;
    top: -1px;
    transition: .2s ease-out;
    padding: 2rem 1rem;
    width: 100%;
    margin: 0px;
    z-index: 2000;
    transition: all 0.3s linear;
    height: 90px;
}

.scrolled {
    padding: 1rem;
    box-shadow: 0 0.325rem 0.375rem 0 rgb(0 0 0 / 10%);
    background-color: white !important;
    height: 70px;

}

.scrolled>a {
    padding: 1rem;
    box-shadow: 0 0.325rem 0.375rem 0 rgb(0 0 0 / 10%);
    background-color: white !important;
}

.items-not-scrolled {
    color: white !important;
    font-size: 1.3rem;
}

.items-scrolled {
    color: #145DA0 !important;
    font-size: 1.3rem;
}

.nav-not-scrolled {
    background-color: #145DA0 !important;
    padding: 10px 20px 10px 20px;
    transition: all 0.3s linear;

}

.nav-scrolled {
    background-color: white !important;
    padding: 10px 20px 10px 20px;
    transition: all 0.3s linear;

}

.items-not-scrolled>a {
    color: white !important;
    font-size: 1.3rem;
}

.items-scrolled>a {
    color: #145DA0 !important;
    font-size: 1.3rem;

}



.size_animate {
    transition: all 0.1s linear;
}

.size_animate:hover {
    transform: scale(1.05);
}