.menus-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: white;
  box-shadow: 0 0 2.5em 0.5em rgba(120, 114, 114, 0.5);
  padding: 80px;
  padding-top: 65px;
}

.custom-ul {
  list-style-type: none;
  padding: 0;
}

.custom-ul > li {
  background-color: #f2f2f2;
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title-li {
  font-size: 18px;
  font-weight: 500;
}

.body-li {
  font-size: 18px;
  font-weight: 400;
}
.menus-description-container {
  display: flex;
  flex-direction: column;
  max-width: 700px;
}

.container-caracteristicas {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  margin-top: 20px;
}

.container-qr {
  display: flex;
  justify-content: space-around;
  max-width: 900px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.qr {
  width: 300px;
  height: 300px;
  margin: 10px;
}


.qr-examples {
    width: 100px;
    height: 100px;
  }

.prueba-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prueba-container > div > span {
  font-size: 18px;
  font-weight: 400;
}

@media screen and (max-width: 991px) {
  .menus-container {
    padding: 30px;
    padding-top: 20px;
  }
}

.heading {
  padding: 1rem 0;
  font-size: 3.5rem;
  text-align: center;
}

.swiper_container {
  height: 100vh;
  padding: 2rem 0;
  position: relative;
}

.swiper-slide-custom {
  max-width: 70%;
  max-height: 100vh;
}

.swiper-slide {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.swiper-slide > div > div > img {
  object-fit: cover;
  max-height: 75vh;
  width: 100%;
}

@media (max-width: 500px) {
  .swiper-slide {
    width: 90%;
  }

  .swiper-slide-custom {
    max-width: 100%;
  }
}

@media (min-width: 500px) and (max-width: 990px) {
  .swiper-slide {
    width: 90%;
  }

  .swiper-slide-custom {
    max-width: 90%;
  }
}

@media (min-width: 990px) and (max-width: 1200px) {
  .swiper-slide-custom {
    max-width: 85%;
  }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.slider-controler {
  position: relative;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-controler .swiper-button-next {
  left: 58% !important;
  transform: translateX(-58%) !important;
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}

.slider-controler .slider-arrow {
  background: var(--white);
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  left: 42%;
  transform: translateX(-42%);
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.slider-controler .slider-arrow ion-icon {
  font-size: 2rem;
  color: #222224;
}

.slider-controler .slider-arrow::after {
  content: "";
}

.swiper-pagination {
  position: relative;
  width: 15rem !important;
  bottom: 1rem;
}

.swiper-pagination .swiper-pagination-bullet {
  filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--primary);
}

.image-inactive {
    filter:  opacity(0.8);
}