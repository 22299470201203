@media screen and (max-width: 640px) {
    .welcome {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - 50px);
        background-size: cover;
        border: 0px;
    }

    .welcome>div {
        display: flex;
        margin: 0px;
        padding: 5px 0px 5px 0px;
        flex-grow: 1;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 50px;
        align-items: center;
        text-align: center;
        height: 100%;
    }

    .left>div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .left>div>img {
        height: 130px;
        margin-bottom: 30px;
    }

    .left>div>span {
        font-weight: 500;
        font-size: 30px;
    }

    .right {
        display: block;
        position: absolute;
        float: right;
    }

    .card-home {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 8px 0 gainsboro;
        padding: 20px 20px 20px 20px;
        /* border-radius: 10px; */
        width: 90%;
        border: 2px;
    }
}

@media screen and (min-width: 640px) and (max-width: 990px) {
    .welcome {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - 50px);
        background-size: cover;
        border: 0px;
    }

    .welcome>div {
        display: flex;
        margin: 0px;
        padding: 5px 0px 5px 0px;
        flex-grow: 1;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 80%;
    }

    .left>div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .left>div>img {
        height: 150px;
        margin-bottom: 30px;
    }

    .left>div>span {
        font-weight: 500;
        font-size: 30px;
    }

    .right {
        display: block;
        position: absolute;
        float: right;

    }

    .card-home {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 8px 0 gainsboro;
        padding: 20px 20px 20px 20px;
        /* border-radius: 10px; */
        width: 90%;
        border: 2px;
    }
}

@media screen and (min-width: 991px) and (max-width: 1279px) {
    .welcome {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - 50px);
        background-size: cover;
        border: 0px;
    }

    .welcome>div {
        display: flex;
        margin: 0px;
        padding: 5px 0px 5px 0px;
        flex-grow: 1;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 80%;
    }

    .left>div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .left>div>img {
        height: 130px;
    }

    .left>div>span {
        font-weight: 500;
        font-size: 30px;
    }

    .right {
        display: block;
        position: absolute;
        float: right;
        height: 80%;

    }

    .right>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 80%;
    }

    .card-home {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 8px 0 gainsboro;
        padding: 20px 20px 20px 20px;
        /* border-radius: 10px; */
        width: 90%;
        border: 2px;
    }
}

@media screen and (min-width: 1280px) {
    .welcome {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: calc(100vh - 50px);
        background-size: cover;
        border: 0px;
    }

    .welcome>div {
        display: flex;
        margin: 0px;
        padding: 5px 0px 5px 0px;
        flex-grow: 1;
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 80%;
    }

    .left>div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .left>div>img {
        height: 150px;
        margin-bottom: 30px;
    }

    .left>div>span {
        font-weight: 500;
        font-size: 30px;
    }

    .right {
        display: block;
        position: absolute;
        float: right;
        height: 80%;

    }

    .right>div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    .card-home {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        backdrop-filter: blur(5px);
        box-shadow: 0 4px 8px 0 gainsboro;
        padding: 20px 20px 20px 20px;
        /* border-radius: 10px; */
        width: 90%;
        border: 2px;
    }
}