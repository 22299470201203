@media screen and (max-width: 800px) {
    .about-us {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 50px);
        min-height: 700px;
        background-size: cover;

    }

    .text-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-area>h1 {
        font-weight: 600;
        font-size: 50px;
        margin-top: 70px;
        text-align: center;
    }
    .text-area>div{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 10px;
    }
    .text-area>div>span{
        font-weight: 400;
        font-size: 25px;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 1000px;
        text-align: center;
    }
    .image-area{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
        
    }
}


@media screen and (min-width: 800px) {
    .about-us {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: calc(100vh - 50px);
        min-height: 700px;
        background-size: cover;

    }

    .text-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .text-area>h1 {
        font-weight: 600;
        font-size: 60px;
        margin-top: 70px;
        text-align: center;
    }
    .text-area>div{
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .text-area>div>span{
        font-weight: 400;
        font-size: 25px;
        margin-top: 20px;
        margin-bottom: 20px;
        max-width: 1000px;
        text-align: center;
    }
    .image-area{
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: 10px;
    }
}