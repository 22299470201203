@media screen and (max-width: 768px) {
    .contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #FAFAFA;
        min-height: 700px;
    }

    .twos {
        margin: 30px 0px 30px 0px;
        display: flex;
        flex-direction: row;
        height: 100%;
        background-color: white;
        width: 80%;
        min-height: 700px;

    }

    .image-areac {
        display: none;
    }

    .text-areac {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 700px;
        padding: 40px 40px 40px 40px;
    }

    .text-areac>h1 {
        color: #145DA0
    }

    .btn-contacto {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.5rem;
        padding: 5px 20px 5px 20px;
        border-radius: 20px;
        color: white;
        background-color: #ED6E56;
        transition: all 0.2s;
        border: 0px;

    }

    .btn-contacto:hover {
        transform: scale(1.1);
        font-size: 1.5rem;
        color: white;
        background-color: #ED6E56;
        border: 0px;
    }
}
@media screen and (min-width: 768px) {
    .contact {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: #FAFAFA;
        min-height: 700px;
    }

    .twos {
        margin: 30px 0px 30px 0px;
        display: flex;
        flex-direction: row;
        height: 100%;
        background-color: white;
        width: 80%;
        min-height: 700px;

    }

    .image-areac {
        background-color: #145DA0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 50%;
        height: 100%;
        min-height: 700px;

    }

    .text-areac {
        display: flex;
        flex-direction: column;
        width: 50%;
        min-height: 700px;
        padding: 40px 40px 40px 40px;
    }

    .text-areac>h1 {
        color: #145DA0
    }

    .btn-contacto {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 1.5rem;
        padding: 5px 20px 5px 20px;
        border-radius: 20px;
        color: white;
        background-color: #ED6E56;
        transition: all 0.2s;
        border: 0px;

    }

    .btn-contacto:hover {
        transform: scale(1.1);
        font-size: 1.5rem;
        color: white;
        background-color: #ED6E56;
        border: 0px;
    }
}