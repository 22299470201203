.email_card {
    background-color: white;
    max-width: 280px;
    border-radius: 10px;
    border-width: 1px;
    box-shadow: 0 4px 8px 0 gainsboro;
    transition: all 0.2s ease-out;
}

.email_card:hover {
    color: white;
    font-weight: 300;
    background-color: #ff5722;
    transform: scale(1.05);
    max-width: 280px;
    border-radius: 10px;
    border-width: 1px;
    box-shadow: 0 4px 8px 0 gainsboro;

}

.email_card>div>div>h1 {
    color: #145DA0
}

.email_card:hover>div>div>h1 {
    color: #EEEEEE
}

.email_card>div>div>span {
    font-weight: 600;
    color: #c41c00
}

.email_card:hover>div>div>span {
    font-weight: 600;
    color: #EEEEEE
}

.email_card>div>div>div>span{
    font-weight: 600;
    color: black;
    font-size: 15px;

}
.email_card:hover>div>div>div>span {
    font-weight: 500;
    color: white;
    font-size: 15px;

}