.contacto {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 1.5rem;
    padding: 10px;
    border-radius: 20px;
    color: white;
    background-color: #ED6E56;
    transition: all 0.2s;
}

.contacto:hover {
    transform: scale(1.1);
    font-size: 1.5rem;
    color: white;
    background-color: #ED6E56;
}

@media screen and (max-width: 991px) {
    .services {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 700px;
        background-size: cover;
    }

    .services>div {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .services>div>div {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
    }

    .title-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .title-area>h1 {
        z-index: 200;
        font-weight: 600;
        font-size: 60px;
        margin-top: 15px;
    }

    .area-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: justify;
        padding: 10px;
    }

    .text-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 100%;
    }

    .text-image>div {
        height: 400px;
        margin-right: -35px;
    }

    .text-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        padding: 20px;
    }

    .text-text>span {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;
    }

    .footer-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
    }
}



@media screen and (min-width: 991px) {
    .services {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 700px;
        background-size: cover;
    }

    .services>div {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .services>div>div {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%), 0 12px 90px 0 rgb(103 151 255 / 11%);
    }

    .title-area {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .title-area>h1 {
        z-index: 200;
        font-weight: 600;
        font-size: 60px;
        margin-top: 15px;
    }

    .area-text {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    .text-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        height: 80%;
    }

    .text-image>div {
        height: 400px;
        margin-right: -35px;
        margin-top: -60px;
        margin-bottom: 30px;
    }

    .text-text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        height: 100%;
        padding-right: 20px;
    }

    .text-text>span {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 400;
    }

    .footer-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}