.email-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: white;
    box-shadow: 0 0 2.5em 0.5em rgba(120, 114, 114, 0.5);
}

.email-container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
}

.email-container-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
}

@media screen and (max-width: 991px) {
    .email-container-description {
        padding: 20px;
    }

}

@media screen and (min-width: 991px) {
    .email-container-description {
        margin-right: 60px;
    }
}