.education {
    --bg-color: #DCE9FF;
    --bg-color-light: #f1f7ff;
    --text-color-hover: #4C5656;
    --box-shadow-color: gba(220, 233, 255, 0.48);
}

.credentialing {
    --bg-color: #B8F9D3;
    --bg-color-light: #e2fced;
    --text-color-hover: #4C5656;
    --box-shadow-color: rgba(184, 249, 211, 0.48);
}

.wallet {
    --bg-color: #CEB2FC;
    --bg-color-light: #F0E7FF;
    --text-color-hover: #fff;
    --box-shadow-color: rgba(206, 178, 252, 0.48);
}

.human-resources {
    --bg-color: #DCE9FF;
    --bg-color-light: #f1f7ff;
    --text-color-hover: #4C5656;
    --box-shadow-color: rgba(220, 233, 255, 0.48);
}

.card {
    width: 220px;
    height: 321px;
    background: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: all 0.2s ease-out;
    text-decoration: none;
    border-radius: 10px;
    border-width: 1px;
    box-shadow: 0 4px 8px 0 gainsboro;
    margin: 10px;
}

.card:hover {
    transform: scale(1.08);
    box-shadow: 0 24px 36px rgba(0, 0, 0, 0.11),
        0 24px 46px var(--box-shadow-color);
}

.card:hover .overlay {
    transform: scale(5) translateZ(0);
}

.card:hover .circle {
    border-color: var(--bg-color-light);
    background: var(--bg-color);
}

.card:hover .circle:after {
    background: var(--bg-color-light);
}

.card:hover p {
    color: var(--text-color-hover);
}

.card:hover a {
    font-size: 18px;
    font-weight: 700;
    color: #145DA0;
    z-index: 200;
}

.card:active {
    transform: scale(1) translateZ(0);
    box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
        0 15px 24px var(--box-shadow-color);
}

.card span {
    font-size: 20px;
    font-weight: 600;
    color: #000;
    z-index: 200;
}

.card button>span {
    font-size: 18px;
    font-weight: 700;
    color: #c41c00;
    z-index: 200;
}

.card p {
    height: 80px;
    font-size: 15px;
    color: #606363;
    margin-top: 5px;
    margin-left: 20px;
    margin-right: 20px;
    z-index: 200;
    font-weight: 500;
    transition: color 0.3s ease-out;
}

.circle {
    width: 131px;
    height: 131px;
    border-radius: 50%;
    border: 2px solid var(--bg-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-out;
}

.circle:after {
    content: "";
    width: 118px;
    height: 118px;
    display: block;
    position: absolute;
    background: var(--bg-color);
    border-radius: 50%;

    transition: opacity 0.3s ease-out;
}

.circle img {
    z-index: s;
    transform: translateZ(0);
}

.overlay {
    width: 118px;
    position: absolute;
    height: 118px;
    border-radius: 50%;
    background: var(--bg-color);
    top: 20px;
    left: 50px;
    z-index: 0;
    transition: transform 0.3s ease-out;
}