.menus-card-container {
    width: 260px;
    border-width: 1px;
    transition: all 0.2s ease-out;
    margin: 10px;
    position: relative;
}
.menus-card-container:hover {
    transform: scale(1.05);

}
.menus-card-container-zig {
    content: "";
    top: -10px;
    /* Ajusta esto para controlar la altura del zigzag */
    left: 0;
    width: 100%;
    height: 20px;
    border-color: #E77568;
    /* Ajusta esto para controlar el tamaño del zigzag */
    background: #E77568;
    /* Color de fondo del zigzag */
    clip-path: polygon(0 100%, 10% 0, 20% 100%, 30% 0, 40% 100%, 50% 0, 60% 100%, 70% 0, 80% 100%, 90% 0, 100% 100%);
    margin-bottom: -1px;
}


.menus-card-top {
    background-color: #E77568;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
}

.menus-card-top>div>span {
    font-size: 40px;
    color: white;
    font-weight: 600;
}

.menus-card-top>div>p {
    font-size: 25px;
    color: white;
    font-weight: 400;
    text-align: center;
    margin: 0px;
    padding: 0px
}

.menus-card-bottom {
    display: flex;
    justify-content: center;
    height: 60px;
    background-color: #E46455;
}

.menus-card-bottom>span {
    font-size: 40px;
    color: white;
    font-weight: 600;
    font-style: italic;
}